import { usePaginationStore } from '~/stores/pagination-store'

export function useListPagination() {
  const currentPage = ref<number>(1)

  const { listPagination } = usePaginationStore()

  const changeListPage = (page: number, action?: () => Promise<void>) => {
    currentPage.value = page
    listPagination.offset = (page - 1) * listPagination.limit
    if (action) {
      action()
    }
  }

  return {
    currentPage,
    listPagination,
    changeListPage,
  }
}
