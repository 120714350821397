const formatShipmentServices = (services: ShipmentAdditionalServices) => {
  const servicesArray = []
  if (services.cod !== null) {
    servicesArray.push({
      code: 'COD',
      data: {
        bank_currency: services.cod.accountCurrency,
        bank_account: services.cod.accountNumber,
        bank_code: services.cod.accountCode,
        bank_variable: services.cod.variableSymbol,
        value: {
          value: Number(services.cod.value.amount),
          currency: services.cod.value.currency,
        },
      },
    })
  }
  if (services.insurance !== null && services.insurance.useCarrierInsurance) {
    servicesArray.push({
      code: 'INS',
      data: {
        value: Number(services.insurance.value.amount),
        currency: services.insurance.value.currency,
      },
    })
  }
  if (services.hdf !== null && services.hdf === true) {
    servicesArray.push({
      code: 'HDF',
    })
  }
  return servicesArray
}

const formatCustoms = (customs: CustomsDeclaration) => {
  const additionalInfo = (customs.additionalInfo || []).map((item) => item)

  if (customs.eori) {
    const existingEoriIdx = additionalInfo.findIndex((item) => item.type === 'eori')
    if (existingEoriIdx > -1) {
      additionalInfo[existingEoriIdx].value = customs.eori
    } else {
      additionalInfo.push({
        type: 'eori',
        value: customs.eori,
      })
    }
  }

  return {
    reason: customs.reason,
    invoice: customs.invoice,
    invoiceFiles: (customs.invoiceFiles || []).map((file) => ({
      token: file.token,
      name: file.name,
      url: file.url,
    })),
    customsRepresentationFile: customs.customsRepresentationFile
      ? {
          token: customs.customsRepresentationFile.token,
          name: customs.customsRepresentationFile.name,
          url: customs.customsRepresentationFile.url,
        }
      : null,
    currency: customs.currency,
    items: (customs.items || []).map((item) => ({
      name: item.name,
      country: item.country,
      quantity: Number(item.quantity),
      value: Number(item.value),
    })),
    additionalInfo,
  }
}

export function formatOmnicartPostRequest(
  sender: AddressForm,
  recipient: AddressForm,
  pickupBranchLocationId: number | null,
  deliveryBranchLocationId: number | null,
  packages: Package[],
  services: ShipmentAdditionalServices,
  customs: CustomsDeclaration | null,
  quote: QuoteResponse | null,
  fromTime?: string | null,
) {
  const defaultCurrency = 'CZK'

  const getPackageTypeCode = (type: string) => {
    const codeMap: { [k: string]: number } = {
      package: 0,
      pallet: 30,
      letter: 10,
      tire: 20,
    }
    return codeMap[type] !== undefined ? codeMap[type] : codeMap.package
  }

  return {
    data: {
      from: {
        id: sender.id,
        company: sender.company,
        firstname: sender.firstname,
        surname: sender.surname,
        street: sender.street,
        city: sender.city,
        zip: sender.zip,
        country: sender.country,
        phone: sender.phone,
        email: sender.email,
        tin: sender.tin,
        vatin: sender.vatin,
        saveAddress: sender.saveAddress,
      },
      to: {
        id: recipient.id,
        company: recipient.company,
        firstname: recipient.firstname,
        surname: recipient.surname,
        street: recipient.street,
        city: recipient.city,
        zip: recipient.zip,
        country: recipient.country,
        phone: recipient.phone,
        email: recipient.email,
        tin: recipient.tin,
        vatin: recipient.vatin,
        saveAddress: recipient.saveAddress,
      },
      packages: packages.map((item) => ({
        weight: Number(item.weight),
        width: Number(item.width),
        height: Number(item.height),
        length: Number(item.length),
        type: getPackageTypeCode(item.type),
      })),
      pickupBranch: null,
      pickupBranchLocation: pickupBranchLocationId,
      deliveryBranch: null,
      deliveryBranchLocation: deliveryBranchLocationId,
      fromTime: fromTime || null,
      serviceId: quote?.serviceGroupId,
      referenceNumber: services.reference,
      currency: quote?.price?.currency || defaultCurrency,
      type: quote?.collectionType,
      pickupDate: services.pickupDate || null,
      services: formatShipmentServices(services),
      customs: customs !== null ? formatCustoms(customs) : null,
    },
  }
}

export const emptyStringToNull = (item: unknown) => {
  const stack = [item]

  while (stack.length > 0) {
    const currentItem = stack.pop()

    Object.keys(currentItem).forEach((key) => {
      const value = currentItem[key]

      if (typeof value === 'object' && value !== null) {
        stack.push(value)
      } else if (value === '' || value === 0) {
        currentItem[key] = null
      }
    })
  }
}
