import { isAxiosError, isCancel } from 'axios'
import type { AxiosResponse } from 'axios'
import type { Maybe } from 'yup'
import cloneDeep from 'lodash/cloneDeep'
import { useUserStore } from '@/stores/user-store'
import { useOmnicartStore } from '@/stores/omnicart-store'
import {
  LazyOrderCartErrorsModal,
  LazyOrderFinishedModal,
  LazyOrderLabelsPrintModal,
  LazyOrderPayOverview,
  LazyOrderSubmitOverview,
  LazyShipmentNewShipment,
} from '#components'

export const shipmentStatusFilterValue = ref('omnicart')

export const shipmentSort: Ref<{ key: string; direction: string }> = ref({
  key: '',
  direction: '',
})

export const shipmentsData = reactive({
  loading: false,
  shipments: [] as ShipmentResponseItem[],
  total: 0,
})

export const shipmentsStatusMap = {
  unpaid: {
    status: [0],
    paid: 0,
  },
  exported: {
    status: [1, 2],
  },
  intransit: {
    status: [3, 4, 5],
  },
  delivered: {
    status: [100, 101],
  },
  canceled: {
    status: [-1],
  },
  problematic: {
    state: 'problematic',
    status: [0, 1, 2, 3, 4, 5],
  },
  all: null,
  omnicart: null,
}

export const shipmentsInitialListFilters: ListFilters = {
  shipmentNumber: null,
  status: 'omnicart',
  referenceNumber: null,
  fromAddress: null,
  fromCountryCode: null,
  toAddress: null,
  toCountryCode: null,
  carrierCode: null,
  codStatus: null,
  codExists: null,
  paid: null,
  state: null,
  pickupDate: {
    from: null,
    to: null,
  },
  deliveryDate: {
    from: null,
    to: null,
  },
  price: {
    from: null,
    to: null,
    includeVat: true,
  },
}

export const shipmentsListFilters = reactive<ListFilters>(cloneDeep(shipmentsInitialListFilters))

let abortController: AbortController | null = null

export const fetchShipments = async (limit?: number) => {
  shipmentsData.loading = true

  const { $api } = useNuxtApp()
  const { listPagination } = useListPagination()
  limit ??= listPagination.limit

  try {
    if (abortController !== null && !abortController.signal.aborted) {
      abortController.abort()
    }

    abortController = new AbortController()

    const sortKey = shipmentSort.value.key
    const sortDir = shipmentSort.value.direction
    const sortQuery = sortKey.length > 0 ? `&order=${sortKey}&direction=${sortDir}` : ''
    const { data } = await $api.post(
      `/user/shipment/list?limit=${limit}&offset=${listPagination.offset}${sortQuery}`,
      formatFiltersForAPI(shipmentsListFilters),
      {
        signal: abortController.signal,
      },
    )

    shipmentsData.shipments = data.list
    shipmentsData.total = data.totalCount
    shipmentsData.loading = false
    reloadShipments.value = false
  } catch (e: unknown) {
    if (isAxiosError(e) && isCancel(e)) {
      return
    }

    // TODO: proper error handling
    console.error('Shipments fech error:', e)
    shipmentsData.shipments = []
    shipmentsData.loading = false
  }
}

export const formatFiltersForAPI = (filters: ListFilters): ShipmentFilterRequest => {
  emptyStringToNull(filters)

  return {
    ...filters,
    status: null,
    ...shipmentsStatusMap[filters.status],
    price: undefined,
    [filters.price.includeVat ? 'price' : 'priceWithoutVat']: {
      ...filters.price,
      includeVat: undefined,
    },
  }
}

export function useStateMap() {
  const { $i18n } = useNuxtApp()

  return new Map([
    [-100, { title: $i18n.t('generic.statuses.unpaid'), color: 'dark' }],
    [-1, { title: $i18n.t('generic.canceled'), color: 'dark' }],
    [0, { title: $i18n.t('generic.statuses.onpickup'), color: 'yellow' }],
    [1, { title: $i18n.t('generic.statuses.onpickup'), color: 'yellow' }],
    [2, { title: $i18n.t('generic.statuses.onpickup'), color: 'yellow' }],
    [3, { title: $i18n.t('generic.statuses.intransit'), color: 'default' }],
    [4, { title: $i18n.t('generic.statuses.ondelivery'), color: 'default' }],
    [5, { title: $i18n.t('generic.statuses.at_pickup_point'), color: 'default' }],
    [100, { title: $i18n.t('generic.statuses.delivered'), color: 'green' }],
    [
      101,
      {
        title: $i18n.t('generic.statuses.closed'),
        title_short: $i18n.t('generic.statuses.closed_short'),
        color: 'green',
      },
    ],
    [undefined, { title: $i18n.t('generic.statuses.omnicart'), color: 'dark' }],
  ])
}

export function usePickupTypeMap() {
  const { $i18n } = useNuxtApp()

  return new Map([
    ['DOOR', $i18n.t('generic.door_pickup')],
    ['COLLECTION_PLACE', $i18n.t('generic.pickup_place')],
    ['PARCEL_SHOP', $i18n.t('generic.collection_parcel_point')],
  ])
}

export function useDeliveryTypeMap() {
  const { $i18n } = useNuxtApp()

  return new Map([
    ['DOOR', $i18n.t('generic.door_delivery')],
    ['PARCEL_SHOP', $i18n.t('generic.delivery_parcel_point')],
  ])
}

export const typePackageMap: { [k: number]: string } = {
  0: 'package',
  10: 'letter',
  20: 'tire',
  30: 'pallet',
}

export const getPackagesSummary = (packages: ShipmentPackageResponse[]) => {
  const typesAndCounts = packages.reduce(
    (acc, item) => {
      const typeName = typePackageMap[item.type] || 'package'
      if (acc[typeName] === undefined) {
        acc[typeName] = 0
      }
      acc[typeName] += 1
      return acc
    },
    {} as { [k: string]: number },
  )
  return typesAndCounts
}

export function usePackageTypeNameMap() {
  const { $i18n } = useNuxtApp()

  return new Map([
    ['default', $i18n.t('generic.type_item.package', 1)],
    ['package', $i18n.t('generic.type_item.package', 1)],
    ['letter', $i18n.t('generic.type_item.letter', 1)],
    ['tire', $i18n.t('generic.type_item.tire', 1)],
    ['pallet', $i18n.t('generic.type_item.pallet', 1)],
  ])
}

export const getPackageTypePluralized = (type: string, n: number): string => {
  const { $i18n } = useNuxtApp()
  switch (type) {
    case 'package':
      return $i18n.t('generic.type_item.package', n)
    case 'letter':
      return $i18n.t('generic.type_item.letter', n)
    case 'tire':
      return $i18n.t('generic.type_item.tire', n)
    case 'pallet':
      return $i18n.t('generic.type_item.pallet', n)
    default:
  }
}

export const openNewShipmentModal = () => {
  if (useUserStore().profile?.orderTypePreferenceSm) {
    const config = useRuntimeConfig()
    const router = useRouter()
    const modal = openModal(
      LazyShipmentNewShipment,
      {
        onClose() {
          modal.close()
        },
        onDone() {
          modal.close()
          useOmnicartStore().fetchOmnicart()
          router.push('/shipments')
        },
      },
      {
        escToClose: false,
        sidePanel: config.public.brandConfig.useModalSheet,
        size: 'xl',
      },
    )
  } else {
    const { $baseApiUrl } = useNuxtApp()
    window.open(`${$baseApiUrl}/konfigurator/vyber-dopravce`, '_blank')
  }
}

export const editShipment = (item: ShipmentResponseItem | OmnicartListItem) => {
  const config = useRuntimeConfig()
  const id: number = item.id
  const modal = openModal(
    LazyShipmentNewShipment,
    {
      editId: id,
      onClose() {
        modal.close()
      },
      onDone() {
        modal.close()
        // TODO: only update the edited shipment to make UX better
        useOmnicartStore().fetchOmnicart()
      },
    },
    {
      escToClose: false,
      sidePanel: config.public.brandConfig.useModalSheet,
      size: 'xl',
    },
  )
}

const reviewShipment = (item: ShipmentResponseItem | OmnicartListItem) => {
  const id = item.id
  const orderToken = item.orderToken

  const { $baseApiUrl } = useNuxtApp()
  if (id && orderToken) {
    window.open(`${$baseApiUrl}/hodnoceni/${orderToken}/${id}`, '_blank')
  }
}

const complainShipment = (item: ShipmentResponseItem | OmnicartListItem) => {
  const iz = item.number

  const { $baseApiUrl } = useNuxtApp()
  if (iz) {
    window.open(`${$baseApiUrl}/reklamace?shipment=${iz}`, '_blank')
  }
}

const changeShipment = (item: ShipmentResponseItem | OmnicartListItem) => {
  const iz = item.number
  const orderToken = item.orderToken

  const { $baseApiUrl } = useNuxtApp()
  const config = useRuntimeConfig()
  const { $i18n } = useNuxtApp()

  if (iz && orderToken) {
    const formUrl = `${$baseApiUrl}/${
      (config.public.externalRoutes.contactForm as { [k: string]: string })[$i18n.locale.value]
    }`
    const formId = 8 // TODO category IDs for other territories/languages
    window.open(`${formUrl}?number=${iz}&token=${orderToken}&category=${formId}`, '_blank')
  }
}

const checkStatus = (item: ShipmentResponseItem | OmnicartListItem) => {
  const iz = item.number
  const orderToken = item.orderToken

  const { $baseApiUrl } = useNuxtApp()
  const config = useRuntimeConfig()
  const { $i18n } = useNuxtApp()

  if (iz && orderToken) {
    const formUrl = `${$baseApiUrl}/${
      (config.public.externalRoutes.contactForm as { [k: string]: string })[$i18n.locale.value]
    }`
    const formId = 7
    window.open(`${formUrl}?number=${iz}&token=${orderToken}&category=${formId}`, '_blank')
  }
}

const openOmnicartOrderModal = (id: number) => {
  const config = useRuntimeConfig()
  const modal = openModal(
    LazyOrderSubmitOverview,
    {
      itemIds: [id],
      onClose() {
        modal.close()
      },
      onDone() {
        modal.close()
        useOmnicartStore().fetchOmnicart()
      },
      onDoneToUnpaid() {
        modal.close()
        useOmnicartStore().fetchOmnicart()
      },
    },
    {
      sidePanel: config.public.brandConfig.useModalSheet,
      size: 'xl',
    },
  )
}

const orderShipment = (item: OmnicartListItem) => {
  if ((item.errors !== undefined && item.errors !== null && item.errors.length > 0) || item.carrier === null) {
    const modal = openModal(LazyOrderCartErrorsModal, {
      errorIds: [item.id],
      validIds: [],
      onClose() {
        modal.close()
      },
      onEdit() {
        editShipment(item)
        modal.close()
      },
      onCloseAndFilterProblematic() {
        modal.close()
      },
      onContinue() {
        modal.close()
      },
    })
  } else {
    openOmnicartOrderModal(item.id)
  }
}

const deleteShipment = async (item: ShipmentResponseItem | OmnicartListItem) => {
  const { $i18n } = useNuxtApp()

  const id: number = item.id
  const deleteConfirm = await openConfirm(
    $i18n.t('components.shipment.delete'),
    $i18n.t('components.shipment.delete_confirmation'),
    $i18n.t('components.shipment.delete'),
    $i18n.t('components.shipment.go_back'),
  )
  if (deleteConfirm) {
    useOmnicartStore().deleteOmnicartItem(id)
  }
}

export const shipmentsDetailData = ref()
export const reloadShipments = ref<boolean>(false)

export const fetchItemDetail = async (item: ShipmentResponseItem | OmnicartListItem) => {
  const { $api } = useNuxtApp()

  if (item.number) {
    try {
      const { data } = await $api.get(`/user/shipment/detail?trackingNumber=${item.number}`)

      if (data) {
        shipmentsDetailData.value = data
      }
    } catch (e) {
      console.log(e)
    }
  }
  return false
}

export const pickupTitleLiftago = (realDate?: string | null): string => {
  const { $i18n } = useNuxtApp()
  return realDate
    ? $i18n.t('components.shipment.pickup_time_real')
    : $i18n.t('components.shipment.pickup_date_estimate')
}

export const deliveryTitleLiftago = (realDate?: string | null): string => {
  const { $i18n } = useNuxtApp()
  return realDate
    ? $i18n.t('components.shipment.pickup_time_real')
    : $i18n.t('components.shipment.delivery_date_estimate')
}

export const pickupDate = (
  type: string,
  hasPlannedPickup: boolean | undefined,
  statusCode: number | undefined,
  date: string | null,
  realDate: string | null,
): string => {
  const { $i18n } = useNuxtApp()
  const pickupDate = realDate || date || undefined

  if (type === 'DOOR' && pickupDate) {
    return LocalDate(pickupDate)
  }

  if (type === 'COLLECTION_PLACE') {
    return hasPlannedPickup && pickupDate ? LocalDate(pickupDate) : '-'
  }

  if (type === 'PARCEL_SHOP') {
    if (statusCode && statusCode >= 3) {
      return pickupDate ? LocalDate(pickupDate) : ''
    } else {
      return $i18n.t('components.shipment.collection_own')
    }
  }

  return ''
}

export const pickupTitleRealDate = (realDate?: string | null): string => {
  const { $i18n } = useNuxtApp()
  return realDate ? $i18n.t('components.shipment.pickup_date') : $i18n.t('components.shipment.pickup_date_estimate')
}

export const pickupTitle = (
  type: string,
  hasPlannedPickup: boolean | undefined,
  statusCode: number | undefined,
  realDate: string | null,
): string => {
  const { $i18n } = useNuxtApp()
  const pickupTitle = pickupTitleRealDate(realDate)

  if (type === 'DOOR') {
    return pickupTitle
  }

  if (type === 'COLLECTION_PLACE') {
    return hasPlannedPickup ? pickupTitle : $i18n.t('components.collection_request.not_planned')
  }

  if (type === 'PARCEL_SHOP') {
    if (statusCode && statusCode >= 3) {
      return pickupTitle
    } else {
      return $i18n.t('components.shipment.collection_own_long')
    }
  }

  return pickupTitle
}

export const deliveryTitleRealDate = (realDate?: string | null): string => {
  const { $i18n } = useNuxtApp()
  return realDate ? $i18n.t('components.shipment.delivery_date') : $i18n.t('components.shipment.delivery_date_estimate')
}

export const deliveryDate = (
  type: string,
  hasPlannedPickup: boolean | undefined,
  statusCode: number | undefined,
  date: string | null,
  realDate: string | null,
  daysInTransit: number,
): string => {
  const { $i18n } = useNuxtApp()
  const deliveryDate = realDate || date || undefined

  if (type === 'DOOR' && deliveryDate) {
    return LocalDate(deliveryDate)
  }

  if (type === 'COLLECTION_PLACE') {
    return hasPlannedPickup && deliveryDate ? LocalDate(deliveryDate) : '-'
  }

  if (type === 'PARCEL_SHOP') {
    if (statusCode && statusCode >= 3) {
      return deliveryDate ? LocalDate(deliveryDate) : ''
    } else {
      if (daysInTransit > 1) {
        return $i18n.t('components.shipment.delivery_to_day_short', { days: daysInTransit })
      } else {
        return $i18n.t('components.shipment.delivery_next_day_after_pickup_short')
      }
    }
  }

  return ''
}

export const deliveryTitle = (
  type: string,
  hasPlannedPickup: boolean | undefined,
  statusCode: number | undefined,
  realDate: string | null,
  daysInTransit: number,
): string => {
  const { $i18n } = useNuxtApp()
  const deliveryTitle = deliveryTitleRealDate(realDate)

  if (type === 'DOOR') {
    return deliveryTitle
  }

  if (type === 'COLLECTION_PLACE') {
    return hasPlannedPickup
      ? $i18n.t('components.shipment.delivery_next_day_after_pickup')
      : $i18n.t('components.collection_request.not_planned')
  }

  if (type === 'PARCEL_SHOP') {
    if (statusCode && statusCode >= 3) {
      return deliveryTitle
    } else {
      if (daysInTransit > 1) {
        return $i18n.t('components.shipment.delivery_to_day', { days: daysInTransit })
      } else {
        return $i18n.t('components.shipment.delivery_next_day_after_pickup')
      }
    }
  }

  return deliveryTitle
}

export const openPrintLabelsModal = async (itemIzs: string[]) => {
  const { $i18n } = useNuxtApp()

  try {
    const modal = openModal(LazyOrderLabelsPrintModal, {
      shipmentIzs: itemIzs,
      onClose() {
        modal.close()
        reloadShipments.value = true
      },
    })
  } catch (e) {
    if (isAxiosError(e) && e.response) {
      const errResponse = e.response as AxiosResponse
      let errMessage = errResponse.data.message || ''
      if (errResponse.status === 400) {
        errMessage = $i18n.t('components.shipment.error_print_label')
      }
      openAlert($i18n.t('generic.actions.print_labels'), errMessage)
    }
  }
}

export const CodMessage = (statusCode: Maybe<number>, dueDate: string | null): string => {
  const { $i18n } = useNuxtApp()

  if (statusCode === 100) {
    return `${$i18n.t('components.shipment.cod_paid_to')} ${LocalDate(dueDate as string)}.`
  } else {
    return $i18n.t('components.shipment.cod_paid_to_seven_workdays')
  }
}

export const openPrintManifest = async (numbers: (string | undefined)[]) => {
  const { $api, $baseApiUrl, $i18n } = useNuxtApp()

  try {
    const { data } = await $api.get('user/token/generate')

    const manifestLink = `${$baseApiUrl}/api/v2/manifest/print?shipments[]=${numbers.join('&shipments[]=')}&token=${
      data.token
    }`

    await $api.get(manifestLink)
    window.open(manifestLink, '_blank')?.focus()
  } catch (e) {
    if (isAxiosError(e) && e.response) {
      const errResponse = e.response as AxiosResponse
      let errMessage = errResponse.data.message
      if (errResponse.status === 400) {
        errMessage = $i18n.t('components.shipment.error_print_manifest')
      }
      openAlert($i18n.t('generic.actions.print_manifest'), errMessage)
    }
  }
}

export const openShipmentsReport = async (numbers: (string | undefined)[]) => {
  const { $api, $baseApiUrl, $i18n } = useNuxtApp()

  try {
    const { data } = await $api.get('user/token/generate')

    const manifestLink = `${$baseApiUrl}/api/v2/report/shipments?shipments[]=${numbers.join('&shipments[]=')}&token=${
      data.token
    }`

    await $api.get(manifestLink)
    window.open(manifestLink, '_blank')?.focus()
  } catch (e) {
    if (isAxiosError(e) && e.response) {
      const errResponse = e.response as AxiosResponse
      let errMessage = errResponse.data.message
      if (errResponse.status === 400) {
        errMessage = $i18n.t('components.shipment.error_excel_shipments_report')
      }
      openAlert($i18n.t('generic.actions.excel_shipments_report'), errMessage)
    }
  }
}

const payShipment = (item: ShipmentResponseItem) => {
  openPayModal(item.orderToken)
}

export const openPayModal = async (orderToken: string) => {
  const { $apiV1, $i18n } = useNuxtApp()
  const orderData = ref<OrderResponse | null>(null)

  try {
    const { data } = await $apiV1.get(`/orders/${orderToken}`)
    orderData.value = data.data
  } catch {
    await openAlert($i18n.t('generic.error'), $i18n.t('generic.unknown_error'))
  } finally {
    if (orderData.value?.invoice?.payment_method === 'PROFORMA') {
      const modal = openModal(
        LazyOrderFinishedModal,
        {
          orderToken,
          onContinueToUnpaid() {
            modal.close()
          },
        },
        {
          size: 'lg',
        },
      )
    } else {
      const modal = openModal(
        LazyOrderPayOverview,
        {
          orderToken,
          orderData: orderData.value,
          onClose() {
            modal.close()
          },
        },
        {
          size: 'xl',
        },
      )
    }
  }
}

export const getItemStatus = (item: ShipmentResponseItem) => {
  return item.statusCode === 0 && !item.isPaid ? -100 : item.statusCode
}

export const getItemProgress = (item: ShipmentResponseItem) => {
  if ([100, 101].includes(getItemStatus(item)!)) {
    return 100
  } else if (getItemStatus(item) === -100) {
    return 0
  }
  return getItemStatus(item)! * 10
}

export const printLabels = (item: ShipmentResponseItem) => {
  const iz = [item.number]
  openPrintLabelsModal(iz)
}

export const printManifestRow = (item: ShipmentResponseItem) => {
  const itemIds = [item.id]
  const tokens = [item]
    .filter((item) => itemIds.includes(item.id))
    .map((item) => item.number)
    .filter((value, index, array) => array.indexOf(value) === index)
  openPrintManifest(tokens)
}

export const formatContactName = (contact: ShipmentContactResponse | null, maxLength = 0) => {
  if (contact === null) {
    return '-'
  }
  let text = ''
  if (contact.company !== undefined && contact.company !== null && contact.company.length > 0) {
    text = contact.company
    if (
      (contact.firstname !== undefined && contact.firstname !== null) ||
      (contact.surname !== undefined && contact.surname !== null)
    ) {
      text += ` (${contact.firstname || ''} ${contact.surname || ''})`
    }
  } else {
    text = `${contact.firstname || ''} ${contact.surname || ''}`
  }
  text = text.length > 0 ? text : '-'
  if (maxLength > 0) {
    text = text.substring(0, maxLength) + '…'
  }
  return text
}

export const liftagoDate = (date: string | null, dateReal: string | null, time?: string) => {
  const { $i18n } = useNuxtApp()

  if (dateReal) {
    return LocalTime(dateReal)
  }

  if (time) {
    const liftagoDeliveryHour = time.slice(0, 2)
    const liftagoDeliveryDate = new Date(date)
    liftagoDeliveryDate.setHours(Number(liftagoDeliveryHour) + 2)
    return `${time.slice(0, 5)} - ${LocalTime(liftagoDeliveryDate.toString())}`
  }

  return $i18n.t('components.shipment.liftago.to_two_hours_from_pickup')
}

const cancelShipment = async (item: ShipmentResponseItem) => {
  const { $i18n } = useNuxtApp()

  if (
    !item.isPaid ||
    ((item.pickupType === 'PARCEL_SHOP' || item.pickupType === 'COLLECTION_PLACE') &&
      useUserStore().profile?.payment_type === 'INVOICE')
  ) {
    const confirm = await openConfirm(
      '',
      $i18n.t('components.shipment.cancel_really'),
      $i18n.t('components.shipment.actions.cancellation'),
      '',
      true,
    )
    if (confirm) {
      const { $apiV1, $i18n } = useNuxtApp()
      const request = { shipments: [item.number] }

      try {
        await $apiV1.post(`/shipments/storno`, request)
      } catch (e) {
        if (isAxiosError(e) && e.response) {
          const errResponse = e.response as AxiosResponse
          const errMessage =
            errResponse.data.errors[0].message ||
            errResponse.data.message ||
            $i18n.t('components.shipment.error_cancelation')
          openAlert($i18n.t('generic.error'), errMessage)
        }
      } finally {
        reloadShipments.value = true
      }
    }

    return
  }

  return changeShipment(item)
}

const showLabel = (show: boolean) => {
  return show
}

const showManifest = (show: boolean) => {
  return show
}

const orderAgainShipment = (item: ShipmentResponseItem, reverse = false) => {
  const config = useRuntimeConfig()
  const modal = openModal(
    LazyShipmentNewShipment,
    {
      orderAgainShipment: item,
      orderReverse: reverse,
      onClose() {
        modal.close()
      },
      onDone() {
        modal.close()
        shipmentStatusFilterValue.value = 'omnicart'
      },
    },
    {
      sidePanel: config.public.brandConfig.useModalSheet,
      size: 'xl',
    },
  )
}

const orderAgainOmnicart = (item: OmnicartResponseItem, reverse = false) => {
  const config = useRuntimeConfig()
  const modal = openModal(
    LazyShipmentNewShipment,
    {
      orderAgainOmnicart: item,
      orderReverse: reverse,
      onClose() {
        modal.close()
      },
      onDone() {
        modal.close()
        // TODO: only update the edited shipment to make UX better
        useOmnicartStore().fetchOmnicart()
      },
    },
    {
      sidePanel: config.public.brandConfig.useModalSheet,
      size: 'xl',
    },
  )
}

const orderAgainShipmentReverse = (item: ShipmentResponseItem) => {
  orderAgainShipment(item, true)
}

const orderAgainOmnicartReverse = (item: OmnicartResponseItem) => {
  orderAgainOmnicart(item, true)
}

export function useActionList(): Record<string, Array<ActionList>> {
  const { $i18n } = useNuxtApp()

  return {
    // Rozpracovane
    undefined: [
      { title: $i18n.t('components.shipment.edit'), action: editShipment },
      { title: $i18n.t('generic.actions.order'), action: orderShipment },
      { title: $i18n.t('components.shipment.order_repeat'), action: orderAgainOmnicart },
      { title: $i18n.t('components.shipment.order_repeat_reverse'), action: orderAgainOmnicartReverse },
      { title: $i18n.t('generic.actions.delete'), color: 'red', action: deleteShipment },
    ],
    // Nezaplacene
    '-100': [
      { title: $i18n.t('components.shipment.actions.pay'), action: payShipment },
      { title: $i18n.t('components.shipment.order_again'), action: orderAgainShipment },
      { title: $i18n.t('components.shipment.order_again_reverse'), action: orderAgainShipmentReverse },
      { title: $i18n.t('components.shipment.actions.cancellation'), action: cancelShipment, color: 'red' },
    ],
    // Vracene
    '999': [
      {
        title: $i18n.t('components.shipment.actions.detail'),
        action: fetchItemDetail,
        hideAsButton: true,
      },
      { title: $i18n.t('components.shipment.order_again'), action: orderAgainShipment },
      { title: $i18n.t('components.shipment.order_again_reverse'), action: orderAgainShipmentReverse },
      { title: $i18n.t('components.shipment.actions.check_shipment_status'), action: changeShipment },
      { title: $i18n.t('components.shipment.actions.review'), action: checkStatus },
      { title: $i18n.t('components.shipment.actions.complain'), action: complainShipment, color: 'red' },
    ],
    // Storno
    '-1': [
      {
        title: $i18n.t('components.shipment.actions.detail'),
        action: fetchItemDetail,
        hideAsButton: true,
      },
      { title: $i18n.t('components.shipment.order_again'), action: orderAgainShipment },
      { title: $i18n.t('components.shipment.order_again_reverse'), action: orderAgainShipmentReverse },
    ],
    // Nevyzvednute
    0: [
      {
        title: $i18n.t('components.shipment.actions.detail'),
        action: fetchItemDetail,
        hideAsButton: true,
      },
      { title: $i18n.t('components.shipment.order_again'), action: orderAgainShipment },
      { title: $i18n.t('components.shipment.order_again_reverse'), action: orderAgainShipmentReverse },
      {
        title: $i18n.t('components.shipment.actions.print_manifest'),
        showManifest,
        action: printManifestRow,
      },
      { title: $i18n.t('components.shipment.actions.check_shipment_status'), action: checkStatus },
      { title: $i18n.t('components.shipment.actions.change'), action: changeShipment },
      { title: $i18n.t('components.shipment.actions.cancellation'), action: cancelShipment, color: 'red' },
    ],
    '1': [
      {
        title: $i18n.t('components.shipment.actions.detail'),
        action: fetchItemDetail,
        hideAsButton: true,
      },
      { title: $i18n.t('components.shipment.order_again'), action: orderAgainShipment },
      { title: $i18n.t('components.shipment.order_again_reverse'), action: orderAgainShipmentReverse },
      { title: $i18n.t('components.label_print_modal.title'), showLabel, action: printLabels },
      {
        title: $i18n.t('components.shipment.actions.print_manifest'),
        showManifest,
        action: printManifestRow,
      },
      { title: $i18n.t('components.shipment.actions.check_shipment_status'), action: checkStatus },
      { title: $i18n.t('components.shipment.actions.change'), action: changeShipment },
      { title: $i18n.t('components.shipment.actions.cancellation'), action: cancelShipment, color: 'red' },
    ],
    // Na vyzvednuti
    '2': [
      {
        title: $i18n.t('components.shipment.actions.detail'),
        action: fetchItemDetail,
        hideAsButton: true,
      },
      { title: $i18n.t('components.shipment.order_again'), action: orderAgainShipment },
      { title: $i18n.t('components.shipment.order_again_reverse'), action: orderAgainShipmentReverse },
      { title: $i18n.t('components.label_print_modal.title'), showLabel, action: printLabels },
      { title: $i18n.t('components.shipment.actions.check_shipment_status'), action: checkStatus },
      { title: $i18n.t('components.shipment.actions.change'), action: changeShipment },
      { title: $i18n.t('components.shipment.actions.cancellation'), action: changeShipment, color: 'red' },
    ],
    // V preprave
    '3': [
      {
        title: $i18n.t('components.shipment.actions.detail'),
        action: fetchItemDetail,
        hideAsButton: true,
      },
      { title: $i18n.t('components.shipment.order_again'), action: orderAgainShipment },
      { title: $i18n.t('components.shipment.order_again_reverse'), action: orderAgainShipmentReverse },
      { title: $i18n.t('components.shipment.actions.check_shipment_status'), action: checkStatus },
      { title: $i18n.t('components.shipment.actions.change'), action: changeShipment },
    ],
    '4': [
      {
        title: $i18n.t('components.shipment.actions.detail'),
        action: fetchItemDetail,
        hideAsButton: true,
      },
      { title: $i18n.t('components.shipment.order_again'), action: orderAgainShipment },
      { title: $i18n.t('components.shipment.order_again_reverse'), action: orderAgainShipmentReverse },
      { title: $i18n.t('components.shipment.actions.check_shipment_status'), action: checkStatus },
      { title: $i18n.t('components.shipment.actions.change'), action: changeShipment },
    ],
    '5': [
      {
        title: $i18n.t('components.shipment.actions.detail'),
        action: fetchItemDetail,
        hideAsButton: true,
      },
      { title: $i18n.t('components.shipment.order_again'), action: orderAgainShipment },
      { title: $i18n.t('components.shipment.order_again_reverse'), action: orderAgainShipmentReverse },
      { title: $i18n.t('components.shipment.actions.check_shipment_status'), action: checkStatus },
      { title: $i18n.t('components.shipment.actions.change'), action: changeShipment },
    ],
    // Dorucene
    '100': [
      {
        title: $i18n.t('components.shipment.actions.detail'),
        action: fetchItemDetail,
        hideAsButton: true,
      },
      { title: $i18n.t('components.shipment.order_again'), action: orderAgainShipment },
      { title: $i18n.t('components.shipment.order_again_reverse'), action: orderAgainShipmentReverse },
      { title: $i18n.t('components.shipment.actions.check_shipment_status'), action: checkStatus },
      { title: $i18n.t('components.shipment.actions.review'), action: reviewShipment },
      { title: $i18n.t('components.shipment.actions.complain'), action: complainShipment, color: 'red' },
    ],
  }
}

export function useSourceMap() {
  const { $i18n } = useNuxtApp()

  return new Map([
    [
      'WWW',
      {
        title: $i18n.t('components.shipment.source.web'),
        color: 'purple',
        icon: 'fal fa-cube',
      },
    ],
    [
      'WEB_APP',
      {
        title: $i18n.t('components.shipment.source.web'),
        color: 'purple',
        icon: 'fal fa-cube',
      },
    ],
    [
      'B2X',
      {
        title: $i18n.t('components.shipment.source.b2x'),
        color: 'purple',
        icon: 'fal fa-cube',
      },
    ],
    [
      'INTEGRATION',
      {
        title: $i18n.t('components.shipment.source.integration'),
        color: 'default',
        icon: 'fal fa-cart-shopping',
      },
    ],
    [
      'API',
      {
        title: $i18n.t('components.shipment.source.api'),
        color: 'green',
        icon: 'fal fa-code',
      },
    ],
    [
      'CSV',
      {
        title: $i18n.t('components.shipment.source.import'),
        color: 'yellow',
        icon: 'fal fa-cloud-arrow-up',
      },
    ],
    [
      'SPREADSHEET',
      {
        title: $i18n.t('components.shipment.source.import'),
        color: 'yellow',
        icon: 'fal fa-cloud-arrow-up',
      },
    ],
    [
      'IMPORT',
      {
        title: $i18n.t('components.shipment.source.import'),
        color: 'yellow',
        icon: 'fal fa-cloud-arrow-up',
      },
    ],
  ])
}

export function formatQuoteRestriction(restriction: string, carrier: string): string | null {
  const { $i18n } = useNuxtApp()

  const restrictionMap: { [k: string]: string } = {
    weight: $i18n.t('components.shipment.restrictions.weight', { carrier }),
    weight_shipment: $i18n.t('components.shipment.restrictions.weight', { carrier }),
    cod_none: $i18n.t('components.shipment.restrictions.cod_none', { carrier }),
    cod_min: $i18n.t('components.shipment.restrictions.cod_min', { carrier }),
    cod_max: $i18n.t('components.shipment.restrictions.cod_max', { carrier }),
    cod_decimal: $i18n.t('components.shipment.restrictions.cod_decimal', { carrier }),
    cod_parcel_limit: $i18n.t('components.shipment.restrictions.cod_parcel_limit', { carrier }),
    insurance_none: $i18n.t('components.shipment.restrictions.insurance_none', { carrier }),
    insurance_max: $i18n.t('components.shipment.restrictions.insurance_max', { carrier }),
    tires: $i18n.t('components.shipment.restrictions.tires', { carrier }),
    pallet: $i18n.t('components.shipment.restrictions.pallet', { carrier }),
    dpd_delivery_branch_d2p: $i18n.t('components.shipment.restrictions.dpd_delivery_branch_d2p', {
      carrier,
    }),
    hydraulic_front: $i18n.t('components.shipment.restrictions.hydraulic_front', { carrier }),
    excluded_area: $i18n.t('components.shipment.restrictions.excluded_area', { carrier }),
    wedo_tire_weight_limit: $i18n.t('components.shipment.restrictions.wedo_tire_weight_limit', { carrier }),
    parcels: $i18n.t('components.shipment.restrictions.parcels', { carrier }),
    pickup_date: $i18n.t('components.shipment.restrictions.pickup_date', { carrier }),
    size_max: $i18n.t('components.shipment.restrictions.size_max', { carrier }),
    volume: $i18n.t('components.shipment.restrictions.size_max', { carrier }),
    perimeter: $i18n.t('components.shipment.restrictions.size_max', { carrier }),
    longest_side_abc: $i18n.t('components.shipment.restrictions.size_max', { carrier }),
    longest_side_ab: $i18n.t('components.shipment.restrictions.size_max', { carrier }),
    longest_side_a: $i18n.t('components.shipment.restrictions.size_max', { carrier }),
    no_documents: $i18n.t('components.shipment.restrictions.no_documents', { carrier }),
  }

  if (restrictionMap[restriction] === undefined) {
    return $i18n.t('components.shipment.restrictions.fallback', { carrier, restriction })
  }

  return restrictionMap[restriction]
}
