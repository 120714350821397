import { defineStore } from 'pinia'

export const usePaginationStore = defineStore('pagination', {
  state: () => ({
    listPagination: {
      limit: 20,
      offset: 0,
    },
  }),
  persist: {
    paths: ['listPagination.limit'],
  },
})
